<template>
	<v-container fluid class=" py-0">


		<v-card class="pa-5 filtro-borda" elevation="0">
            <div>
                
                    <v-row>
                        <v-col cols="2" md="2" lg="2" xl="1" class="px-2">
                            <v-btn
                                class="white--text"
                                color="primary"
                                :disabled="['PROCESSADO','APROVADO'].includes(status)"
                                @click="abrirImportar"
                            >
                                <v-icon left>mdi-upload</v-icon> IMPORTAR
                            </v-btn>
                        </v-col> 
                        <v-col cols="2" md="2" lg="2" xl="1" class="px-2">
                            <ClusterEspaco 
                                :alertClusterType="alertClusterType"
                                :dadosUpdateDay="dadosUpdateDay"
                                @refresh-alert="validaUpdateCluster"
                            /> 
                        </v-col>
                        <v-col cols="3" class="px-2">
                            <v-text-field
                                label="Mês"
                                v-model="mesImportacao"
                                :rules="regra.mesImportacao"
                                :min="mesProximo"
                                @change="alterarPeriodo"
                                :disabled="['PROCESSADO','APROVADO'].includes(status)"
                                required 
                                outlined
                                dense
                                type="month"
                            ></v-text-field>
                        </v-col> 
                    </v-row>
                
            </div>
        </v-card>

		<v-dialog v-model="dialogoImportar" width="90%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Importar planilha com ferramentas
					
				</v-card-title>
				<v-card-text>
					<v-alert
						class="mt-4"
						text
						type="info"
						border="left"
						style="font-size: 12px;"
					>
						<ul>
							<li>
								Confirme o envio da planilha ferramentas corretamente
							</li>
							
						</ul>
					</v-alert>
					<v-row no-gutters class="mt-4">
						
					
						<v-col cols="6" class="px-2">
							<v-form ref="form" v-model="isXlsx">
								<v-file-input
									label="Arquivo XLSX"
									outlined
									dense
									v-model="arquivoXLSX"
									:rules="regra.xlsx"
									accept=".xlsx"
								></v-file-input>
							</v-form>
						</v-col>
						<v-col cols="3" class="px-2">
							<v-btn
								color="primary"
								:disabled="!isXlsx"
								@click="importar()"
							>
								<v-icon left>mdi-upload</v-icon>
								Importar Arquivo
							</v-btn>
						</v-col>
                        <v-col cols="3" class="px-2">
                           
                        </v-col>
					</v-row>
					<v-overlay :value="carregando">
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay> 
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogoImportar = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-row>
			<v-col cols="5">
				<v-card class="px-5 py-3 br-15 w-100 my-6" elevation="0">
                    <v-row class="mb-2">
                        <v-col cols="2" class="d-flex pt-2">
                           
                        </v-col>
                        <v-col cols="8" class="pt-2">
                            <h2 class="text-center" style="font-weight: normal">
                                Adesivo Vitrine
                            </h2>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-end pt-2">
                            <v-menu offset-y min-width="180">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        color="#f2f2f2"
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        class="px-1"
                                        style="min-width: unset"
                                    >
                                        <v-icon color="#CCC">mdi-menu</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense nav>
                                    <download-csv
                                        :data   = "dataTableAdesivoVitrine"
                                        :separator-excel="true"
                                        name="adesivo_vitrine.csv"
                                        encoding="windows-1252"
                                        >
                                        <v-icon color="grey lighten-1"
                                        >mdi-microsoft-excel</v-icon>
                                        Exportar CSV
                                    </download-csv>
                                    
                                </v-list>
                                
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-data-table
                        class="elevation-0 tabela"
                        :headers="headersAdesivoVitrine"
                        :items="dataTableAdesivoVitrine"
                        :items-per-page="5"
                        
                        
                        
                        :header-props="{ sortIcon: 'mdi-chevron-down' }"
                        multi-sort
                    >
                    </v-data-table>
                    <v-overlay :absolute="true" :value="loadLista">
                        <v-progress-circular indeterminate size="40" />
                    </v-overlay>
                </v-card>
			</v-col>
            <v-col cols="7">
				<v-card class="px-5 py-3 br-15 w-100 my-6" elevation="0">
                    <v-row class="mb-2">
                        <v-col cols="2" class="d-flex pt-2">
                           
                        </v-col>
                        <v-col cols="8" class="pt-2">
                            <h2 class="text-center" style="font-weight: normal">
                                Vitrine
                            </h2>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-end pt-2">
                            <v-menu offset-y min-width="180">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        color="#f2f2f2"
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        class="px-1"
                                        style="min-width: unset"
                                    >
                                        <v-icon color="#CCC">mdi-menu</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense nav>
                                    <download-csv
                                        :data   = "dataTableVitrine"
                                        :separator-excel="true"
                                        name="vitrine.csv"
                                        encoding="windows-1252"
                                        >
                                        <v-icon color="grey lighten-1"
                                        >mdi-microsoft-excel</v-icon>
                                        Exportar CSV
                                    </download-csv>
                                </v-list>
                                
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-data-table
                        class="elevation-0 tabela"
                        :headers="headersVitrine"
                        :items="dataTableVitrine"
                        :items-per-page="5"
                        
                        
                        
                        :header-props="{ sortIcon: 'mdi-chevron-down' }"
                        multi-sort
                    >
                    </v-data-table>
                    <v-overlay :absolute="true" :value="loadLista">
                        <v-progress-circular indeterminate size="40" />
                    </v-overlay>
                </v-card>
			</v-col>                                 			
			<v-col cols="6">
				<v-card class="px-5 py-3 br-15 w-100 my-6" elevation="0">
                    <v-row class="mb-2">
                        <v-col cols="2" class="d-flex pt-2">
                           
                        </v-col>
                        <v-col cols="8" class="pt-2">
                            <h2 class="text-center" style="font-weight: normal">
                                Macro Espaços
                            </h2>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-end pt-2">
                            <v-menu offset-y min-width="180">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        color="#f2f2f2"
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        class="px-1"
                                        style="min-width: unset"
                                    >
                                        <v-icon color="#CCC">mdi-menu</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense nav>
                                    <download-csv
                                        :data   = "dataTableMacroEspacos"
                                        :separator-excel="true"
                                        name="macro_espacos.csv"
                                        encoding="windows-1252"
                                        >
                                        <v-icon color="grey lighten-1"
                                        >mdi-microsoft-excel</v-icon>
                                        Exportar CSV
                                    </download-csv>
                                </v-list>
                                
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-data-table
                        class="elevation-0 tabela"
                        :headers="headersMacroEspacos"
                        :items="dataTableMacroEspacos"
                        :items-per-page="5"
                        :header-props="{ sortIcon: 'mdi-chevron-down' }"
                        multi-sort
                    >
                    </v-data-table>
                    <v-overlay :absolute="true" :value="loadLista">
                        <v-progress-circular indeterminate size="40" />
                    </v-overlay>
                </v-card>
			</v-col>
			<v-col cols="6">
				<v-card class="px-5 py-3 br-15 w-100 my-6" elevation="0">
                    <v-row class="mb-2">
                        <v-col cols="2" class="d-flex pt-2">
                           
                        </v-col>
                        <v-col cols="8" class="pt-2">
                            <h2 class="text-center" style="font-weight: normal">
                                Código Consolidado
                            </h2>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-end pt-2">
                            <v-menu offset-y min-width="180">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        dark
                                        color="#f2f2f2"
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        class="px-1"
                                        style="min-width: unset"
                                    >
                                        <v-icon color="#CCC">mdi-menu</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense nav>
                                    <download-csv
                                        :data   = "dataTableCodigoConsolidado"
                                        :separator-excel="true"
                                        name="codigo_consolidado.csv"
                                        encoding="windows-1252"
                                        >
                                        <v-icon color="grey lighten-1"
                                        >mdi-microsoft-excel</v-icon>
                                        Exportar CSV
                                    </download-csv>
                                </v-list>
                                
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-data-table
                        class="elevation-0 tabela"
                        :headers="headersCodigoConsolidado"
                        :items="dataTableCodigoConsolidado"
                        :items-per-page="5"
                        :header-props="{ sortIcon: 'mdi-chevron-down' }"
                        multi-sort
                    >
                    </v-data-table>
                    <v-overlay :absolute="true" :value="loadLista">
                        <v-progress-circular indeterminate size="40" />
                    </v-overlay>
                </v-card>
			</v-col>
            <Overlay :overlay="load" />
            <v-overlay :value="carregando">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
		</v-row>
	</v-container>
</template>

<script>
import Overlay from "./_components/Overlay.vue";
import ClusterEspaco from "../../cadastro/inventario/_components/ClusterEspaco.vue";

export default {
    name: 'PlanilhaFerramentas',
	components: {
        ClusterEspaco,
		Overlay,
	},
	data() {
		return {
            mesImportacao: null,
            data_aprovacao: null,
            status: 'INICIADO',
            data_invetario: null,
            alertClusterType: "warning",
            dadosUpdateDay: [],
            erros: [],
            erro: {},
            errosLabels: [],
            // permiteEdicao: false,
            carregando: false,
            headerErros: [],
            paginationErros: {},
			dialogoImportar: false,
			load: false,
            loadLista: true,
            total: 0,
            pagination: null,
            dataTableAdesivoVitrine: [],
            headersAdesivoVitrine: [
                { text:"Cd. Filial", value: "Cd. Filial", align: "center" },
                { text:"Descrição", value: "Descrição", align: "center" },
                { text:"Cluster", value: "Cluster", align: "center" },
                { text:"Código", value: "CODIGO", align: "center" },
            ],
            dataTableVitrine: [],
            headersVitrine: [
                { text:"Id Java", value: "ID JAVA", align: "center" },
                { text:"Código", value: "ID", align: "center" },
                { text:"Bandeira", value: "BANDEIRA", align: "center" },
                { text:"Filial", value: "FILIAL", align: "center" },
                { text:"Perfil", value: "PERFIL", align: "center" },
                { text:"Digital", value: "DIGITAL", align: "center" },
                { text:"Status", value: "STATUS", align: "center" },
                { text:"Modelo de vitrine", value: "MODELO DE VITIRINE", align: "center" },
                { text:"Ocupação", value: "OCUPAÇÃO", align: "center" },
                { text:"Total Espaços", value: "Total Espaços", align: "center" },
            ],
            headersMacroEspacos: [
                { text:"Espaço", value: "espaco", align: "center" },
                { text:"Apendice 1", value: "apendice1", align: "center" },
                { text:"Apendice 2", value: "apendice2", align: "center" },
                { text:"Apendice 3", value: "apendice3", align: "center" },
                { text:"Apendice 4", value: "apendice4", align: "center" },
            ],
            dataTableMacroEspacos: [],
            headersCodigoConsolidado: [
                { text:"Id Espaço", value: "Id Espaço", align: "center" },
                { text:"Espaço", value: "Espaço", align: "center" },
            ],
            dataTableCodigoConsolidado: [],
            regra: {
				xlsx: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(!!v && [".xlsx", ".XLSX"].includes(v.name.substr(-5))) ||
						"O arquivo deve ser do tipo XLSX.",
				],
                mesImportacao: [
					(v) => !!v || "O campo mês é obrigatório.",
					(v) =>
						(!!v && v > this.mesAtual) ||
						"O mês deve ser posterior ao mês atual.",
				],
				
			},
			isXlsx: false,
            arquivoXLSX: '',
		};
	},
    created() {
		this.mes = this.mesProximo;
		this.mesImportacao = this.mesProximo;
		this.validaUpdateCluster();
	},
    computed: {
		mesAtual() {
			return this.$moment().format("YYYY-MM");
		},
		mesProximo() {
			return this.$moment()
				.add(1, "months")
				.format("YYYY-MM");
		},
        // permiteEdicao () {
        //     console.log(this.data_aprovacao == null, this.data_aprovacao)
        //     return this.data_aprovacao == null
        // }
	},
	methods: {
        async alterarPeriodo () {
            var formData = new FormData();
			formData.append("data", JSON.stringify({guiadebolso_id: localStorage.guiadebolso_id, mes:this.mesImportacao + '-01'}));
            let _response = await this.$http().post(
				"/importacaoinventario/updateMes",
                formData
			);
        },
        async validaUpdateCluster() {
			this.dadosUpdateDay = [];
			this.alertClusterType = null;

			let _response = await this.$http().get(
				"/inventario/checaUpdateDay",
				{}
			);

			if (!_response.data.success) {
				this.$dialog.notify.error(
					"Ops! Falha ao conferir a atualização do cluster, por favor, tente novamente.",
					{
						position: "top-right",
						timeout: 5000,
					}
				);
			}

			this.dadosUpdateDay = _response.data.dados || [];
			const _data = this.dadosUpdateDay[0] || [
				{ validate_day: false, alteracao_cluster_filiais: false },
			];

			console.log("_data", _data);

			this.alertClusterType =
				_data.validate_day && !_data.alteracao_cluster_filiais
					? "success"
					: "warning";
		},
        abrirImportar() {

			this.arquivoXLSX = null;
			this.erro = false;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				console.log(error)
			}

			this.dialogoImportar = true;
		},
        async importar() {
			this.carregando = true;

			var data = { guiadebolso_id: localStorage.guiadebolso_id };

			var formData = new FormData();

			formData.append("files", this.arquivoXLSX);
			formData.append("data", JSON.stringify(data));

			this.arquivoCSV = null;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			var response = await this.$http().post(
				"/importacaoinventario/importarferramentas",
				formData
			);

			this.carregando = false;

			if (response.data.sucesso) {
				this.buscarFerramentas();
				this.dialogoImportar = false;
			} else {
				this.buscarFerramentas();
				this.erros = response.data.erros;
				this.pagination.page = 1;
				this.erro = true;
			}
            // this.carregando = true;
		},
        async buscarFerramentas() {
            this.loadLista = true;
            var response = await this.$http().get("/importacaoinventario/dadosferramentas/" + localStorage.guiadebolso_id);
            if ((response || { status: 500 }).status != 200) {
                this.loadLista = false;
                return null;
            }
            this.dataTableAdesivoVitrine = response.data.adesivo_vitrine
            this.dataTableVitrine = response.data.vitrine
            this.dataTableMacroEspacos = response.data.macro_espacos
            this.dataTableCodigoConsolidado = response.data.codigo_consolidado
            // this.permiteEdicao = (response.data.data_importacao.length == 0)
            this.mesImportacao = this.$moment(response.data.mes)
				.format("YYYY-MM");
            this.data_aprovacao = response.data.data_aprovacao
            console.log(this.data_aprovacao)
            this.data_invetario = response.data.data_invetario
            this.status = response.data.status
            console.log('this.status',this.status)
            this.loadLista = false;
        }
	},
    async mounted() {
        this.buscarFerramentas()
    }
};
</script>

<style></style>
