var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-5 py-3 br-15 w-100",attrs:{"elevation":"0"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-toolbar-title',{staticClass:"page-title"},[_vm._v("Acordos Comerciais")])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.travaInventario},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[(_vm.usuarioTipo == 'rd')?_c('v-btn',{staticClass:"new-button w-100",attrs:{"color":"primary","disabled":_vm.travaInventario},on:{"click":function($event){return _vm.$refs.formulario.novo()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-thick")]),_vm._v(" Criar acordo ")],1):_vm._e()],1)]}}])},[_c('span',[_vm._v("Criação de acordo desabilitada até a finalização da aprovação do inventário.")])])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"new-button w-100",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Exportar ")],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.exportar('acordos')}}},[_c('v-list-item-title',[_vm._v("Acordos")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.exportar('produtosPDV')}}},[_c('v-list-item-title',[_vm._v("Produtos PDV")])],1),(_vm.usuarioTipo != 'cubo')?_c('v-list-item',{on:{"click":function($event){return _vm.exportar('produtosEcommerce')}}},[_c('v-list-item-title',[_vm._v("Produtos Ecommerce")])],1):_vm._e(),(_vm.usuarioTipo != 'cubo')?_c('v-list-item',{on:{"click":function($event){return _vm.exportar('fornecedor')}}},[_c('v-list-item-title',[_vm._v("Fornecedor")])],1):_vm._e(),(_vm.usuarioTipo != 'cubo')?_c('v-list-item',{on:{"click":function($event){return _vm.exportar('exportacaocubo')}}},[_c('v-list-item-title',[_vm._v("Exportar produto cubo")])],1):_vm._e()],1)],1)],1)],1),_c('filtro',{ref:"filtro",on:{"filtrar":_vm.filtrar,"limpar":_vm.limpar}})],1),(_vm.usuarioTipo == 'rd')?_c('indicadores',{ref:"indicadores",attrs:{"indicadores":_vm.indicadores,"filtro":_vm.$refs.filtro}}):_vm._e(),_c('formulario',{ref:"formulario",on:{"filtrar":_vm.filtrar}}),_c('v-card',{staticClass:"br-15 pa-5 involucroAcordo",attrs:{"elevation":"0"}},[_c('v-data-table',{staticClass:"tabela paginacao-esquerda flex-table",attrs:{"headers":_vm.headers,"items":_vm.acordos,"server-items-length":_vm.totalAcordos,"item-key":"id_acordo_campanha","show-select":"","fixed-header":"","multi-sort":"","items-per-page":5,"footer-props":{
                    'items-per-page-options': [5, 10, 50, 100, 500, -1],
                    itemsPerPageText: 'Linhas por páginas',
                    ofText: 'de',
                    pageText: '{0}-{1} de {2}',
                },"options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
                    var item = ref.item;
                    var isSelected = ref.isSelected;
                    var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected,"disabled":(!_vm.fnLiberar &&
                                !_vm.statusAcesso(
                                    item.id_status,
                                    'aprovar_reprovar'
                                )) ||
                            (_vm.fnLiberar &&
                                (!_vm.statusAcesso(item.id_status, 'liberar') ||
                                    item.mes_acordo != _vm.mesAtual)),"on-icon":(!_vm.fnLiberar &&
                                !_vm.statusAcesso(
                                    item.id_status,
                                    'aprovar_reprovar'
                                )) ||
                            (_vm.fnLiberar &&
                                (!_vm.statusAcesso(item.id_status, 'liberar') ||
                                    item.mes_acordo != _vm.mesAtual))
                                ? 'mdi-checkbox-blank-outline'
                                : 'mdi-checkbox-marked'},on:{"input":function($event){return select($event)}}})]}},{key:"item.actions",fn:function(ref){
                                var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"180","content-class":" acordo-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-1",staticStyle:{"min-width":"unset"},attrs:{"dark":"","color":"#f2f2f2","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#CCC"}},[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('v-card',[_c('v-list',{attrs:{"dense":"","nav":""}},[(!_vm.travaInventario && (
                                        _vm.statusAcesso(
                                            item.id_status,
                                            'aprovar_reprovar'
                                        ) &&
                                        _vm.fnAprovar &&
                                        _vm.usuarioTipo == 'rd')
                                    )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.reprovar(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Reprovar Acordo")])],1)],1):_vm._e(),(!_vm.travaInventario && (
                                        _vm.statusAcesso(
                                            item.id_status,
                                            'aprovar_reprovar'
                                        ) &&
                                        _vm.fnAprovar &&
                                        _vm.usuarioTipo == 'rd')
                                    )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.aprovar(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Aprovar Acordo")])],1)],1):_vm._e(),(!_vm.travaInventario && (
                                        (item.mes_acordo > _vm.mesAtual ||
                                            (item.mes_acordo == _vm.mesAnterior &&
                                                item.retroativo)) &&
                                        _vm.statusAcesso(
                                            item.id_status,
                                            'editar'
                                        ) &&
                                        _vm.usuarioTipo == 'rd')
                                    )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.formulario.editar(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Editar Acordo ")])],1)],1):_vm._e(),(!_vm.travaInventario && (
                                        item.mes_acordo >= _vm.mesAtual &&
                                        _vm.statusAcesso(
                                            item.id_status,
                                            'replicar_cancelado'
                                        ) &&
                                        _vm.usuarioTipo == 'rd')
                                    )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.formulario.replicarAcordo(
                                            item,
                                            'cancelado'
                                        )}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-autorenew")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Replicar Cancelado")])],1)],1):_vm._e(),(['rd', 'cubo'].includes(_vm.usuarioTipo))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.historico(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-document-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Histórico")])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.formulario.editar(item, true)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-document-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Exibir Acordo")])],1)],1),(!_vm.travaInventario && (
                                        item.termino == item.mes_acordo &&
                                        item.termino >= _vm.mesAnterior &&
                                        _vm.statusAcesso(
                                            item.id_status,
                                            'renovar'
                                        ) &&
                                        _vm.usuarioTipo == 'rd')
                                    )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.renovacao(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-update")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Renovar Acordo")])],1)],1):_vm._e(),(item.planograma && !['Cubo','Fila Única'].includes(item.espaco_macro_pdv))?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.planograma(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-image")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Planograma")])],1)],1):_vm._e(),(!_vm.travaInventario && !['Cubo','Fila Única'].includes(item.espaco_macro_pdv) && (
                                        _vm.statusAcesso(
                                            item.id_status,
                                            'editar_planograma'
                                        ) && ['rd', 'cubo'].includes(_vm.usuarioTipo))
                                    )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.planogramaEditar(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-image-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Editar Planograma")])],1)],1):_vm._e(),(
                                        item.obs_departamento &&
                                        _vm.usuarioTipo == 'rd'
                                    )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.dialogDepartamento(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-clipboard-text-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Obs. Departamento")])],1)],1):_vm._e(),(
                                        item.obs_contrato && _vm.usuarioTipo == 'rd'
                                    )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.dialogContrato(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-clipboard-text")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Obs. Contrato")])],1)],1):_vm._e(),(!_vm.travaInventario && (
                                        _vm.statusAcesso(
                                            item.id_status,
                                            'cancelar'
                                        ) && _vm.usuarioTipo == 'rd')
                                    )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.cancelar(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cancelar Acordo")])],1)],1):_vm._e(),(!_vm.travaInventario && (
                                        _vm.statusAcesso(
                                            item.id_status,
                                            'liberar'
                                        ) &&
                                        (item.mes_acordo == _vm.mesAtual ||
                                            item.retroativo) &&
                                        _vm.usuarioTipo == 'rd')
                                    )?_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$refs.dialogo.liberar(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Liberar Acordo")])],1)],1):_vm._e()],1)],1)],1)]}}],null,true),model:{value:(_vm.acordoSelecionado),callback:function ($$v) {_vm.acordoSelecionado=$$v},expression:"acordoSelecionado"}}),_c('div',{staticClass:"table-footer-prepend d-flex pl-2 pt-4 align-center justify-end"},[(!_vm.fnLiberar)?_c('v-btn',{staticClass:"new-button ml-5",attrs:{"disabled":_vm.travaInventario  || (
                        !_vm.acordoSelecionado.filter(function (e) { return _vm.statusAcesso(e.id_status, 'aprovar_reprovar'); }
                        ).length || !_vm.fnAprovar),"color":"primary"},on:{"click":function($event){return _vm.$refs.dialogo.aprovar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check-circle")]),_vm._v(" Aprovar Acordos ")],1):_vm._e(),(!_vm.fnLiberar)?_c('v-btn',{staticClass:"new-button ml-5",attrs:{"disabled":_vm.travaInventario  || (
                        !_vm.acordoSelecionado.filter(function (e) { return _vm.statusAcesso(e.id_status, 'aprovar_reprovar'); }
                        ).length || !_vm.fnAprovar),"color":"error"},on:{"click":function($event){return _vm.$refs.dialogo.reprovar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-circle")]),_vm._v(" Reprovar acordos ")],1):_vm._e(),(_vm.fnLiberar)?_c('v-btn',{staticClass:"new-button ml-5",attrs:{"disabled":!_vm.acordoSelecionado.filter(
                            function (e) { return _vm.statusAcesso(e.id_status, 'liberar') &&
                                e.mes_acordo == _vm.mesAtual &&
                                        _vm.usuarioTipo == 'rd'; }
                        ).length,"color":"primary"},on:{"click":function($event){return _vm.$refs.dialogo.liberar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Liberar acordos ")],1):_vm._e()],1),_c('dialogo',{ref:"dialogo",on:{"aprovarAcordo":_vm.aprovarAcordo,"liberarAcordo":_vm.liberarAcordo,"filtrar":_vm.filtrar,"renovar":_vm.renovar}}),_c('formulario',{ref:"formulario",on:{"filtrar":_vm.filtrar}}),_c('v-overlay',{attrs:{"value":_vm.carregando}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-snackbar',{staticStyle:{"z-index":"400"},attrs:{"color":_vm.alertaColor,"timeout":_vm.alertaTimeout,"top":""},model:{value:(_vm.alerta),callback:function ($$v) {_vm.alerta=$$v},expression:"alerta"}},[_c('v-alert',{attrs:{"type":_vm.alertaColor}},[_vm._v(" "+_vm._s(_vm.alertaTexto)+" ")]),_c('v-btn',{staticClass:"float-right",attrs:{"dark":"","text":""},on:{"click":function($event){_vm.alerta = false}}},[_vm._v(" Fechar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }