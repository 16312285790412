<template>
    <v-form ref="form">
        {{ filtro.cluster }}
        <v-row class="mt-4">
            <v-col cols="4" class="py-0">
                <v-text-field
                    v-model="buscaFilial"
                    class="input-button input-cinza"
                    label="Pesquisar"
                    outlined
                    dense
                    hide-details
                >
                    <template>
                        <v-icon slot="append" color="white">mdi-magnify</v-icon>
                    </template>
                </v-text-field>
            </v-col>
            <v-col
                cols="4"
                class="py-0"
                v-if="disabledOrganicos && (acordo.idAcordos || []).length > 0"
            >
                <b
                    >Clusters da criação deste acordo ({{
                        acordo.idAcordos[0]
                    }}): </b
                >{{ clustersAcordoName }}
            </v-col>
            <v-col cols="4" class="py-0">
                <v-select
                    label="Cluster Tabela"
                    outlined
                    dense
                    multiple
                    class="input-cinza"
                    v-model="cluster_tabela"
                    :items="listaClusterTabela"
                    item-value="value"
                    @change="verifiqueApenasUmNaoRede()"
                    item-text="text_concat"
                    :menu-props="menuProps"
                >
                    <template v-slot:selection="{ item, index }">
                        <span
                            v-if="cluster_tabela.length === 1 && index === 0"
                            >{{ item.text }}</span
                        >
                        <span
                            v-if="cluster_tabela.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                            >{{ cluster_tabela.length }} selecionados</span
                        >
                    </template>
                </v-select>
            </v-col>
            <v-col cols="4" align="end" class="py-0">
                <v-btn
                    class="new-button px-5"
                    color="yellow"
                    dark
                    @click="limpar()"
                >
                    <v-icon left>mdi-filter-off</v-icon> Limpar
                </v-btn>
                <v-btn
                    class="ml-4 new-button px-5"
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>mdi-filter</v-icon> Filtrar
                </v-btn>
            </v-col>
        </v-row>
        <v-data-table
            :headers="listaHeader"
            :items="listaLojas"
            :search="buscaFilial"
            item-key="id_filial"
            :items-per-page="5"
            :footer-props="{
                'items-per-page-options': [5, 10, 50, 100, 500, -1],
                itemsPerPageText: 'Linhas por páginas',
                ofText: 'de',
                pageText: '{0}-{1} de {2}',
            }"
            :options.sync="pagination"
            class="tabela-filial"
        >
            <template v-slot:header="{}">
                <thead>
                    <tr>
                        <th colspan="2">
                            <!-- <div class="d-flex align-center">
                                <p class="text-filtro mr-3">
                                    Filtrar somente lojas com oportunidade
                                </p>
                                <v-switch
                                    v-model="filtro.filter_lojas_op"
                                    label=""
                                    class="mt-0"
                                />
                            </div> -->
                        </th>

                        <th colspan="3">
                            <FilialLegendas :organicos="disabledOrganicos" />
                        </th>

                        <th
                            v-for="p in getPeriodo()"
                            :key="p"
                            class="v-data-table-header text-center th-separador top-header"
                        >
                            <div
                                style="
                                    width: 24px;
                                    margin-left: auto;
                                    margin-right: auto;
                                    display: block;
                                "
                                class="text-center"
                            >
                                <v-checkbox
                                    :key="p"
                                    v-model="todasFiliais[p]"
                                    @click="selecionaTudo(p, acordo.fnEditar)"
                                    color="#fff"
                                    dark
                                    class="ma-0 pa-0"
                                    :disabled="disabledOrganicos"
                                    hide-details
                                />
                            </div>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:[`item.oportunidade_loja`]="{ item }">
                <v-chip
                    class="ma-2"
                    :color="item.oportunidade_loja ? 'green' : 'red'"
                    label
                    text-color="white"
                >
                    {{ item.oportunidade_loja ? "SIM" : "NÃO" }}
                </v-chip>
            </template>
            <template
                v-for="periodo in listaPeriodo"
                v-slot:[`item.quantidade${periodo}`]="{ item }"
                class="th-separador"
            >
                <v-simple-checkbox
                    :key="periodo"
                    :ripple="false"
                    :disabled="item['oportunidade' + periodo] == false"
                    :value="
                        item['quantidade' + periodo] ||
                        item['disponivel' + periodo]
                    "
                    :off-icon="iconOff(periodo, item).icon"
                    :title="iconOff(periodo, item).title"
                    :color="item['presente_acordo' + periodo] ? 'primary' : ''"
                    @input="checkFilial(item, `quantidade${periodo}`, $event)"
                    @click="alteraFilial(item)"
                />
            </template>
        </v-data-table>

        <v-row no-gutters class="mb-3 mt-1">
            <v-col cols="12" class="px-2 ml-auto" align="end">
                <v-btn
                    class="new-button px-5 mx-2"
                    color="error"
                    :disabled="disabledOrganicos"
                    @click="limpaSelecionadas(true)"
                >
                    <v-icon left>mdi-trash-can</v-icon> Limpar Filiais
                </v-btn>
                <v-btn
                    class="new-button px-5 mx-2"
                    color="primary"
                    @click="carregarSelecionadas()"
                >
                    <v-icon left>mdi-arrow-up</v-icon> Carregar Filiais Salvas
                </v-btn>
                <v-btn
                    class="new-button px-5 ml-2"
                    color="primary"
                    @click="salvarSelecionadas()"
                >
                    <v-icon left>mdi-arrow-down</v-icon> Salvar Filiais
                    Selecionadas
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :headers="listaHeaderFiliais"
            :items="somaFiliais"
            item-key="nome"
            class="elevation-0 tabela"
            hide-default-footer
            disable-sort
        >
        </v-data-table>
        <div v-if="dialogoCamposFaltando" class="dialogo-erro" width="700px">
            <v-card class="br-15">
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Por favor retorne as etapas anteriores e preencha os
                        seguintes campos:</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-list>
                        <v-list-item
                            v-for="campo in camposFaltando"
                            :key="campo"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    campo
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>
        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
        <v-overlay
            :value="mostraOverlay"
            :absolute="true"
            :opacity="0.6"
            :z-index="3"
        ></v-overlay>
        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
    </v-form>
</template>

<script>
import FilialLegendas from "./_components/FilialLegendas.vue";

export default {
    name: 'campanhaFiliais',
    inject: ["listaCampos"],
    components: {
        FilialLegendas,
    },
    props: ["value"],

    data() {
        return {
            carregando: false,
            pagination: {},
            dialogoCamposFaltando: false,
            lista: {
                bandeiraRegiao: [],
                bandeiraEstado: [],
                bandeiraRegiaoCidade: [],
                bandeiraEstadoCidade: [],
                bandeiraCluster: [],
                bandeiraTamanho: [],
                cluster: [],
                espaco: [],
            },
            acordo: {},
            filtro: {
                id_cluster: [],
                filter_lojas_op: false,
            },
            dados: [],
            dadosTemp: [],
            buscaFilial: "",
            filiais: [],
            filiaisSalvas: [],
            todasFiliais: [],
            somaFiliais: [],
            somaFiliaisValor: [],
            semAcesso: false,
            clustersAcordoName: "",
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            cluster_tabela: [],
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            processoFiltro: false,
            filtroPendente: false,
        };
    },
    async created() {
        var lista = await this.listaCampos;
        this.lista.bandeiraEstado = lista.bandeiraEstado;
        this.lista.bandeiraEstadoCidade = lista.bandeiraEstadoCidade;
        this.lista.bandeiraCluster = lista.bandeiraCluster;
        this.lista.bandeiraTamanho = lista.bandeiraTamanho;
        this.lista.check_out = lista.check_out;
        this.lista.espaco = lista.espaco;
        this.lista.cluster = lista.cluster;

    },
    watch: {
        "filtro.id_cluster": function (val) {
            if (this.value.clusterRede != "REDE") {
                // if (!this.carregando) this.cluster_tabela = []
            } else {
                // if (this.cluster_tabela.length != this.listaClusterTabela.length) {
                //     this.$nextTick(() => {
                //         this.cluster_tabela = this.listaClusterTabela.map(
                //             (item) => item.value
                //         );
                //     });
                // }
            }

        },
    },
    computed: {
        disabledOrganicos() {
            return !this.value.flag_teste && this.espacoOrganico() > 0;
        },
        listaPeriodo() {
            return this.getPeriodo("");
        },
        listaRegiao() {
            return this.lista.bandeiraRegiao;
        },
        listaEstado() {
            return this.lista.bandeiraEstado;
        },
        listaCidade() {
            return this.lista.bandeiraEstadoCidade.filter(
                (obj) =>
                    (this.filtro.estado.length > 0 &&
                        this.filtro.estado.includes(obj.estado)) ||
                    this.filtro.estado.length == 0
            );
        },
        listaClusterTabela() {
			if(!this.lista.cluster) return []

            const cluster = this.lista.cluster
            console.log('importante cluster',cluster)
            let _clusters = [...new Set(cluster.map((e) => e.cluster_macro))];
			var _result = [];

			for (let x of _clusters) {
                
				let _values = [
					...new Set(
						cluster
							.filter((e) => e.cluster_macro == x)
							.map((v) => v.value)
					),
				];

				_result.push({
					text: x,
					value: _values,
				});
			}
			return _result;
		},
        listaCluster() {
            return this.lista.cluster;
        },
        listaTamanho() {
            return []
        },
        listaHeader() {
            var header = [
                {
                    text: "ID JAVA",
                    value: "id_java",
                    align: "center",
                    class: "th-separador th-id-java",
                },
                {
                    text: "Estado",
                    value: "estado",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Cidade",
                    value: "cidade",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Cluster Filial",
                    value: "cluster",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Tamanho",
                    value: "tamanho",
                    align: "center",
                    class: "th-separador",
                },

            ];
            for (let mesAtual of this.value.periodo){
                mesAtual = new Date(mesAtual + "-15");
                header.push({
                    text:
                        ("0" + (mesAtual.getMonth() + 1)).slice(-2) +
                        "/" +
                        mesAtual.getFullYear(),
                    value:
                        "quantidade_" +
                        mesAtual.getFullYear() +
                        "_" +
                        (mesAtual.getMonth() + 1),
                    align: "center",
                    class: "th-separador",
                });

                // mesAtual.setMonth(mesAtual.getMonth() + 1);
            }

            return header;
        },
        listaHeaderFiliais() {
            var header = [
                {
                    text: "",
                    value: "nome",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Total",
                    value: "total",
                    align: "center",
                    class: "th-separador",
                },
            ];
            for (let mesAtual of this.value.periodo){
                mesAtual = new Date(mesAtual + "-15");

                header.push({
                    text:
                        ("0" + (mesAtual.getMonth() + 1)).slice(-2) +
                        "/" +
                        mesAtual.getFullYear(),
                    value:
                        "quantidade_" +
                        mesAtual.getFullYear() +
                        "_" +
                        (mesAtual.getMonth() + 1),
                    align: "center",
                    class: "th-separador",
                });

                // mesAtual.setMonth(mesAtual.getMonth() + 1);
            }

            return header;
        },
        listaLojas() {
            let _filter = !this.filtro.filter_lojas_op
                ? this.dados
                : this.dados.filter(
                      (e) => e.oportunidade_loja == this.filtro.filter_lojas_op
                  );
            return _filter;
        },
        camposFaltando() {
            const campos = [];

            if (this.value.periodo.length == 0) {
                campos.push("Período");
            }
            if (!this.value.clusterRede) {
                campos.push("Cluster/Rede");
            }
            if (!this.value.cota) {
                campos.push("Cota");
            }
            if (!this.value.id_espaco_pdv && this.value.cota !== 'ADESÃO') {
                campos.push("Espaço PDV");
            }

            return campos;
        },
        mostraOverlay() {
            if (this.camposFaltando.length > 0) {
                return true;
            }
            return false;
        },
    },
    methods: {
        verifiqueApenasUmNaoRede() {
            console.log('this.cluster_tabela',this.cluster_tabela)
            if (this.value.clusterRede == "REDE") {
                if (this.cluster_tabela.length != this.listaClusterTabela.length) {
                    this.$nextTick(() => {
                        this.cluster_tabela = this.listaClusterTabela.map(
                            (item) => item.value
                        );
                    });
                }    
            } else {
                this.filtroPendente = true
                if (this.cluster_tabela.length > 1) {
                    this.cluster_tabela = [];
                }
            }    
        },
        verifiqueTodosNaoRede() {
            if (this.value.clusterRede == "REDE") {
                if (this.cluster_tabela.length != this.listaClusterTabela.length) {
                    this.$nextTick(() => {
                        this.cluster_tabela = this.listaClusterTabela.map(
                            (item) => item.value
                        );
                    });
                }    
            } else {
                this.filtroPendente = true
                if (this.cluster_tabela.length == this.listaClusterTabela.length) {
                    this.cluster_tabela = [];
                }
            }    
        },
        iconOff(periodo, item) {
            const _isDisable = item["oportunidade" + periodo] == false;
            const _inventario = item["inventario" + periodo];
            const _compOrganico = item["comprometido_organico" + periodo];
            var _result = {
                icon: "mdi-checkbox-blank-outline",
                title: "",
            };

            switch (true) {
                //Não tem inventário
                case _isDisable && _inventario == 0:
                    _result = {
                        icon: "mdi-minus-box-outline",
                        title: "Não possui inventário",
                    };
                    break;
                //Não tem oportunidade
                case _isDisable && _inventario > 0:
                    _result = {
                        icon: "mdi-checkbox-blank-off-outline",
                        title:
                            `Não possui oportunidade` +
                            (_compOrganico > 0
                                ? ` , cluster alocado no ID Acordo ${_compOrganico}`
                                : ""),
                    };
                    break;
            }

            return _result;
        },
        checkFilial(item, key, value) {
            if (!this.disabledOrganicos) item[key] = value;

            if (this.disabledOrganicos /* && this.value.fnEditar */) {
                this.$dialog.notify.warning(
                    `Para acordos do tipo campanha, não é permitido editar as filiais.`,
                    {
                        position: "top-right",
                        timeout: 15000,
                    }
                );
            }
        },
        async setAcordo() {
            if (this.camposFaltando.length > 0) {
                this.dialogoCamposFaltando = true;

                return;
            }
            this.dialogoCamposFaltando = false;

            // setTimeout(() => {
                console.log('this.value.clustersNoAcordo', this.value.clustersNoAcordo)
            if(this.value.clustersNoAcordo){
                this.filtro.id_cluster = this.value.clustersNoAcordo;
            }
            if ((this.value.clusterRede != "REDE") && (this.filiais.length == 0)) {
                  //  this.cluster_tabela = []
            }            
                if (this.dados.length === 0) {
                    this.filtrar(true,true)
                    // await this.filtrar(true, true);
                }
            // }, 3000)
        },
        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },
        getPeriodo(campo = "quantidade") {
            const periodo = this.value.periodo.map((e) => {
                const mes =  new Date(e + "-15");
                return `${campo}_${mes.getFullYear()}_${mes.getMonth() + 1}`;
            });

            return periodo;
        },
        limpaFiltro() {
            this.filtro = {
                estado: [],
                cidade: [],
                id_cluster: [],
                tamanho: [],
                filter_lojas_op: false,
            };
            this.buscaFilial = "";
        },
        async limpar() {
            this.limpaFiltro();

            await this.filtrar();
        },
        async filtrar(marcaOrganico = true, created = false) {
            this.processoFiltro = true;
            this.carregando = !this.carregando;

            var data = {};
            data.filtro = {
                // id_cluster: this.value.clustersNoAcordo || [],
            };
            if (!created) {
                // console.log('passou aqui')
                data.filtro.id_cluster = []
                for (var item of this.cluster_tabela) {
                    data.filtro.id_cluster.push(...item)
                }
                this.filtro.id_cluster = data.filtro.id_cluster
            }
            data.acordo = this.value;
            /// faz o id_espaco_pdv virar id_espaco
            data.acordo.id_espaco = this.value.id_espaco_pdv;

            let response = [];

            response = await this.$http().post("/acordoCampanhas/filiais", {
                data: data,
            });
            // }

            if (created) this.filtro.id_cluster = this.value.clustersNoAcordo || [];
            if (created) this.cluster_tabela = [this.value.clustersNoAcordo] || [];

            console.log('created',created,this.filtro,this.value,this.cluster_tabela )

            const _dadosConverte = response.data.map((e) => {
                let _op =
                    this.getPeriodo("").filter(
                        (m) => e["oportunidade" + m] > 0 && !e["quantidade" + m]
                    ).length > 0;
                // console.log(_op)    

                let _qtd =
                    this.getPeriodo("").filter(
                        (m) =>
                            e["quantidade" + m] &&
                            !this.filtro.id_cluster.includes(e.id_cluster) &&
                            !this.filtro.id_cluster.includes(
                                e.id_cluster_acordo
                            )
                    ).length > 0;
                // console.log(_qtd)        

                for (var mes of this.getPeriodo()) {
                    e["orig_" + mes] = this.$helpers.cloneObj(e[mes]);
                }
                return {
                    ...e,
                    oportunidade_loja: _op,
                    comprometido_loja_foracluster: _qtd,
                };
            });
            console.log('_dadosConverte',_dadosConverte)    

            this.dados = _dadosConverte.filter(
                (e) => {
                    // console.log(this.filtro.id_cluster,e.id_cluster)
                    return this.filtro.id_cluster.includes(e.id_cluster) 
                     ||
                    this.filtro.id_cluster.includes(e.id_cluster_acordo) ||
                    this.filtro.id_cluster.length == 0 ||
                    e.comprometido_loja_foracluster
                }
            );

            console.log('dados',this.dados)    

            if (created) {
                var _clustersAcordoName = new Set();
                console.log('this.listaClusterTabela',this.listaClusterTabela)

                for (var i of this.listaClusterTabela) {
                    var _countCompEdicao = 0;
                    let _count = _dadosConverte.filter((e) => {
                        var _op = false;
                        var _ck = false;
                        for (const m of this.getPeriodo("")) {
                            if (
                                e["oportunidade" + m] > 0 &&
                                !e["quantidade" + m]
                            ) {
                                _op = true;
                            }

                            if (
                                e["quantidade" + m] &&
                                e.id_cluster == i.value &&
                                !_ck
                            ) {
                                _countCompEdicao++;
                                _ck = true;
                            }
                        }

                        return i.value.includes(e.id_cluster) && _op;
                    }).length;

                    i.text_concat = `
						${i.text} - (Filiais - ${_count} disponíveis
						${_countCompEdicao > 0 ? "/ " + _countCompEdicao + " neste acordo" : ""})
					`;

                    if ((this.value.clusters_acordo || []).includes(i.value))
                        _clustersAcordoName.add(i.text);
                }
                // console.log(_clustersAcordoName,[..._clustersAcordoName].join("/"))
                this.clustersAcordoName = [..._clustersAcordoName].join("/");
            }
            console.log('this.clustersAcordoName',this.clustersAcordoName)

            for (const e of this.filiais) {
                var index = this.dados.findIndex(
                    (element) => element.id_filial == e.id_filial
                );

                if (index >= 0) {
                    this.dados.splice(index, 1, { ...e });
                }
            }

            for (const e of this.getPeriodo()) {
                this.todasFiliais[e] = false;
            }

            if (this.value.fnEditar && this.filiais.length == 0) {
                for (const dado of this.dados) {
                    dado.alterado = 1;
                    dado.comprometido = 0;

                    for (const e of this.getPeriodo()) {
                        if (dado[e]) {
                            dado.comprometido = 1;
                        }
                    }
                }

                if (created) this.salvarSelecionadas(true);
            }

            if (
                this.espacoOrganico() &&
                marcaOrganico &&
                !this.value.fnLeitura &&
                (!this.value.fnEditar ||
                    (this.value.fnEditar && this.disabledOrganicos))
            ) {
                for (const e of this.getPeriodo()) {
                    this.todasFiliais[e] = true;
                    this.selecionaTudo(e, true);
                }
            }

            this.pagination.page = 1;
            if (this.value.clusterRede == "REDE") {
                this.salvarSelecionadas();
            }
            this.carregando = !this.carregando;
            this.processoFiltro = false;
            this.filtroPendente = false;
        },
        selecionaTudo(mes, force = false) {
            if (this.disabledOrganicos && !force) return;

            for (const e of this.dados) {
                if (e[mes.replace("quantidade", "oportunidade")] * 1 > 0) {
                    e[mes] = this.todasFiliais[mes] ? true : false;
                    e.alterado = 1;
                    e.comprometido = 0;

                    for (const p of this.getPeriodo()) {
                        if (e[p]) {
                            e.comprometido = 1;
                        }
                    }
                }
            }
        },
        alteraFilial(item) {
            if (this.disabledOrganicos) return;

            var dado = this.dados.find(
                (element) => element.id_filial == item.id_filial
            );

            dado.alterado = 1;
            dado.comprometido = 0;

            for (const e of this.getPeriodo()) {
                if (dado[e]) {
                    dado.comprometido = 1;
                }
            }
        },
        getCusto(mes, arrayCusto = []) {
            const _mesArray = mes.replace("quantidade_", "").split("_");
            const _mes =
                _mesArray[0] +
                "-" +
                (_mesArray[1] * 1 < 10 ? "0" + _mesArray[1] : _mesArray[1]) +
                "-01";

            var _custo = arrayCusto.filter((e) => e.mes == _mes);

            return _custo.length > 0 ? _custo[0].custo : 0;
        },
        disableCheckCusto(mes, arrayCusto = []) {
            const _mesArray = mes.split("_");
            const _mes =
                _mesArray[1] +
                "-" +
                (_mesArray[2] * 1 < 10 ? "0" + _mesArray[2] : _mesArray[2]) +
                "-01";

            var _custo = arrayCusto.filter((e) => e.mes == _mes);

            return _custo.length == 0;
        },
        async salvarSelecionadas(force = false) {
            if (this.value.clusterRede != "REDE") {
                if (this.cluster_tabela.length == 0) {
                    this.alerta = true;
                    this.alertaColor = "error";
                    this.alertaTimeout = -1;
                    this.alertaTexto = "Usando o modo cluster é obrigatorio escolher pelo menos um cluster tabela";
                    return false
                }
                if (this.filtroPendente) await this.filtrar()
            }
            if (!force) {
                this.$emit("limpaDescontos");
            }
            const _filiais = this.$helpers.cloneObj( this.dados);

            console.log('_filiais',_filiais)

            var soma = [];
            const _clusters = new Set();
            const clustersNoAcordo = new Set();
            soma[0] = {};
            soma[0].total = 0;
            for (const p of this.getPeriodo()) {
                soma[0][p] = 0;
            }

            for (const e of _filiais) {
                var fnFilial = 0;
                for (const p of this.getPeriodo()) {
                    if (force) {
                        soma[0][p] += e[p] * 1;
                        //console.log(e[p])
                        if (e[p]) {
                            fnFilial = 1;
                        }
                    } else {
                        if(e[p]){

                            soma[0][p] += 1;
                            fnFilial = 1;
                        }
                    }
                }
                if (fnFilial > 0) _clusters.add(e.cluster);
                if (fnFilial > 0) clustersNoAcordo.add(e.id_cluster);
                soma[0].total += fnFilial;
            }

            console.log(_clusters)

            soma[0].nome = `Filiais Salvas (${[..._clusters].join(" / ")})`;

            console.log(soma)
            this.value.clustersNoAcordo = [...clustersNoAcordo];

            console.log(this.value,this.lista)
            
            let clusterNomes = []
            for (var a of this.value.clustersNoAcordo) {
                for (var b of this.lista.cluster) {
                    if (a == b.value) {
                        clusterNomes.push({id:a,nome:b.text})
                    }
                }
            }
            console.log('clusterNomes', clusterNomes)
            this.value.clustersNomes = clusterNomes
            this.somaFiliais = soma;
            console.log(JSON.stringify(this.somaFiliais))
            this.filiais = _filiais;
            console.log(this.filiais)
            console.log(this.value)
        
        },
        carregarSelecionadas() {
            this.dados = this.$helpers.cloneObj(this.filiais);
            this.pagination.page = 1;
        },
        limpaSelecionadas(fnServidor = false) {
            if (this.disabledOrganicos && fnServidor) return;

            for (const e of this.getPeriodo()) {
                this.todasFiliais[e] = false;
            }

            this.dados = [];
            this.filiais = [];
            this.somaFiliais = [];
            this.clustersNoAcordo = [];
            this.limpaFiltro();

            if (fnServidor) {
                //this.limpaFiltro();

                this.filtrar(false);
            }
        },
        espacoOrganico() {
            /// Para a regra de campanhas, a seleção de filiais deve ser semelhante ao
            /// que é feito para espaços organicos.

            return 1;
        },

        validate() {
            if (!this.acordo.fnLeitura) {
                if (this.somaFiliais.length == 0) {
                    return false;
                } else {
                    for (const p of this.getPeriodo()) {
                        if (this.somaFiliais[0][p] == 0) {
                            return false;
                        }
                    }
                }
            }
            return true;
        },
    },
};
</script>

<style>
.theme--light.v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.15) !important;
}

.text-filtro {
    color: #000;
    font-size: 0.9rem;
    margin: 0 !important;
    align-self: center;
}
.top-header {
    background-color: #24796e;
}
.tabela-filial .v-data-table__wrapper {
    border-radius: 10px;
    border-right: 1px solid #ccc;
}
.th-id-java {
    border-radius: 10px 0 0 0;
}

.tabela-filial tr > td:first-child {
    border-left: 1px solid #ccc;
}
</style>
