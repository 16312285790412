<template>
    <v-container fluid class=" py-0">
        <v-card class="pa-5 filtro-borda" elevation="0">
            <v-row>
                <v-col cols="2" md="2" lg="2" xl="2" class="px-2">
                    <v-btn
                        class="white--text"
                        color="primary"
                        :disabled="['PROCESSADO','APROVADO'].includes(status)"
                        @click="abrirImportar"
                    >
                        <v-icon left>mdi-upload</v-icon> IMPORTAR
                    </v-btn>
                </v-col> 
                
            </v-row>
        </v-card>
        <v-dialog v-model="dialogoImportarML" width="60%">
            <v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Importar CSV para Espaços Extras
				</v-card-title>
                <v-card-text>
                    <v-alert
                        class="mt-4"
                        text
                        type="info"
                        border="left"
                        style="font-size: 12px;"
                    >
                        <ul>
                            <strong>Para realizar a importação dos espaços extras, siga as instruções abaixo.</strong><br/>
                            <li>
                                Importação apenas para espaços não apresentados no guia de bolso
                            </li>    
                            <li>
                                Seguem as colunas e informação de quais campos são obrigatórios:<br/>
                            </li>
                                &nbsp;    o	Id_java - campo obrigatório<br/>
                                &nbsp;    o	Código_rd - campo obrigatório<br/>
                                &nbsp;    o	Quantidade - campo obrigatório<br/>
                                &nbsp;    o	Nome do espaço – Este é o único campo que não é obrigatório na importação
                            
                        </ul>
                    </v-alert>
                    <v-row no-gutters class="mt-4">
                        
                    
                        <v-col cols="9" class="px-2">
                            <v-form ref="form" v-model="isCSV">
                                <v-file-input
                                    label="Arquivo CSV"
                                    outlined
                                    dense
                                    v-model="arquivoCSV"
                                    :rules="regra.csv"
                                    accept=".csv"
                                ></v-file-input>
                            </v-form>
                        </v-col>
                        <v-col cols="3" class="px-2">
                            <v-btn
                                color="primary"
                                :disabled="!isCSV"
                                @click="importar()"
                            >
                                <v-icon left>mdi-upload</v-icon>
                                Importar Arquivo
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-expand-transition>
                        <div v-show="erro">
                            <v-row no-gutters class="mb-0">
                                <v-col cols="6">
                                    <download-csv
                                        :data="erros"
                                        :labels="errosLabels"
                                        name="erros.csv"
                                        delimiter=";"
                                    >
                                        <v-btn color="primary">
                                            <v-icon left
                                                >mdi-microsoft-excel</v-icon
                                            >
                                            Exportar Erros
                                        </v-btn>
                                    </download-csv>
                                </v-col>
                            </v-row>
                            <v-data-table
                                :headers="headerErros"
                                :items="erros"
                                :items-per-page="5"
                                :options.sync="paginationErros"
                                class="elevation-0 data-table-2"
                            ></v-data-table>
                        </div>
                        <v-overlay :value="carregando">
                            <v-progress-circular indeterminate size="64"></v-progress-circular>
                        </v-overlay> 
                    </v-expand-transition>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="dialogoImportarML = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>    
        </v-dialog>
        <v-row>
			<v-col cols="12">
				<v-card class="px-5 py-3 br-15 w-100 my-6" elevation="0">
						<v-row class="mb-2">
								<v-col cols="4" class="d-flex pt-2">
									  
										
								</v-col>
								<v-col cols="6" class="pt-2">
										<h2 class="text-center" style="font-weight: normal">
                                            Espaços Extras
										</h2>
								</v-col>
								<v-col cols="2" class="d-flex justify-end pt-2">
										<v-menu offset-y min-width="180">
												<template v-slot:activator="{ on, attrs }">
														<v-btn
																dark
																color="#f2f2f2"
																v-bind="attrs"
																v-on="on"
																small
																class="px-1"
																style="min-width: unset"
														>
																<v-icon color="#CCC">mdi-menu</v-icon>
														</v-btn>
												</template>
												<v-list dense nav>
													<download-csv
														:data   = "dataPontosExtras"
														:separator-excel="true"
														name="pontosextras.csv"
														encoding="utf-8"
                                                        delimiter=";"
														>
														<v-icon color="grey lighten-1"
														>mdi-microsoft-excel</v-icon>
														Exportar CSV
													</download-csv>
												</v-list>
												
										</v-menu>
								</v-col>
						</v-row>
						<v-data-table
								class="elevation-0 tabela"
								:headers="headersML"
								:items="dataPontosExtras"
								:items-per-page="10"
								:header-props="{ sortIcon: 'mdi-chevron-down' }"
								multi-sort
						>
						</v-data-table>
						<v-overlay :absolute="true" :value="loadLista">
								<v-progress-circular indeterminate size="40" />
						</v-overlay>
				</v-card>
			</v-col>
			<Overlay :overlay="load" />
		</v-row>
    </v-container>
</template>

<script>
import Overlay from "./_components/Overlay.vue";
export default {
    name: 'PontosExtras',
    data() {
        return {
            busca : '',
            erro: {},
			dialogoImportarML: false,
            carregando: false,
            status: 'INICIADO',
			itemUpdate: null,
            data_aprovacao: null,
            data_invetario: null,
			load: false,
			loadLista: true,
			permiteEdicao: false,
			total: 0,
			pagination: null,
			dataPontosExtras: [],
			headersML: [
                { text:"Id Java", value: "id_java", align: "center" },
				{ text:"Código RD", value: "codigo_rd", align: "center" },
				{ text:"Espaço", value: "espaco", align: "center" },
				{ text:"QT Inventário", value: "quantidade", align: "center" },
			],
			regra: {
				csv: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
						"O arquivo deve ser do tipo CSV.",
				],
				
			},
			isCSV: false,
            arquivoCSV: '',
            headerErros: [
                { text: "Descrição do Erro", value: "msg", align: "left" },
            ],
            erros: [],
            errosLabels: { msg: "Descrição do Erro" },
            paginationErros: {},
        };
    },
    components: {
		Overlay,
	},
    methods: {
		abrirImportar() {
            this.arquivoCSV = null;
            this.erro = false;

            // try {
			// 	this.$refs.form.resetValidation();
			// } catch (error) {
			// 	console.log(error)
			// }

            this.dialogoImportarML = true;
		},
		async importar() {
            this.carregando = true;

            var data = { guiadebolso_id: localStorage.guiadebolso_id };

            var formData = new FormData();

            formData.append("files", this.arquivoCSV);
            formData.append("data", JSON.stringify(data));

            this.arquivoCSV = null;

            try {
                this.$refs.form.resetValidation();
            } catch (error) {
                //console.log(error)
            }

            var response = await this.$http().post(
                "/importacaoinventario/importarpontosextras/",
                formData
            );

            this.carregando = false;

            if (response.data.sucesso) {
                this.abrirPontosExtras();
                this.dialogoImportarML = false;
            } else {
                this.abrirPontosExtras();
                this.erros = response.data.erros;
                this.paginationErros.page = 1;
                this.erro = true;
            }
		},
        async abrirPontosExtras () {
            this.loadLista = true;
            var response = await this.$http().get("/importacaoinventario/dadospontosextras/" + localStorage.guiadebolso_id);

            if ((response || { status: 500 }).status != 200) {
                this.$dialog.notify.error(
                    "Falha buscar os dados, por favor, tente novamente.",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                this.loadLista = false;
                return null;
            }
            // console.log(response)
            this.permiteEdicao = (response.data.data_importacao.length == 0)
            this.data_aprovacao = response.data.data_aprovacao
            this.data_invetario = response.data.data_invetario
            this.dataPontosExtras = response.data.pontosextras
            this.status = response.data.status
            this.loadLista = false;
        }
	},
    async mounted() {
        this.abrirPontosExtras()        
    }
};
</script>

<style></style>