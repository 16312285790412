<template>
	<v-container fluid class=" py-0">


		<v-card class="pa-5 filtro-borda" elevation="0">
            <div>
                
                    <v-row>
                        <v-col cols="2" md="2" lg="2" xl="2" class="px-2">
                            <v-btn
                                class="white--text"
                                color="primary"
								:disabled="['PROCESSADO','APROVADO'].includes(status)"
                                @click="abrirImportar"
                            >
                                <v-icon left>mdi-upload</v-icon> IMPORTAR
                            </v-btn>
                        </v-col> 
                    
                    </v-row>
                
            </div>
        </v-card>

				<v-dialog v-model="dialogoImportar" width="90%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Importar planilha com farmácias
					
				</v-card-title>
				<v-card-text>
					<v-alert
						class="mt-4"
						text
						type="info"
						border="left"
						style="font-size: 12px;"
					>
						<ul>
							<li>
								Confirme o envio da planilha farmácias corretamente
							</li>
							
						</ul>
					</v-alert>
					<v-row no-gutters class="mt-4">
						
					
						<v-col cols="6" class="px-2">
							<v-form ref="form" v-model="isXlsx">
								<v-file-input
									label="Arquivo XLSX"
									outlined
									dense
									v-model="arquivoXLSX"
									:rules="regra.xlsx"
									accept=".xlsx"
								></v-file-input>
							</v-form>
						</v-col>
						<v-col cols="3" class="px-2">
							<v-btn
								color="primary"
								:disabled="!isXlsx"
								@click="importar()"
							>
								<v-icon left>mdi-upload</v-icon>
								Importar Arquivo
							</v-btn>
						</v-col>
					</v-row>
						<v-overlay :value="carregando">
					<v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay> 
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="pr-4"
						@click="dialogoImportar = false"
					>
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>		
		<v-row>
			<v-col cols="12">
				<v-card class="px-5 py-3 br-15 w-100 my-6" elevation="0">
						<v-row class="mb-2">
								<v-col cols="4" class="d-flex pt-2">
									  
										
								</v-col>
								<v-col cols="6" class="pt-2">
										<h2 class="text-center" style="font-weight: normal">
												Farmácias
										</h2>
								</v-col>
								<v-col cols="2" class="d-flex justify-end pt-2">
										<v-menu offset-y min-width="180">
												<template v-slot:activator="{ on, attrs }">
														<v-btn
																dark
																color="#f2f2f2"
																v-bind="attrs"
																v-on="on"
																small
																class="px-1"
																style="min-width: unset"
														>
																<v-icon color="#CCC">mdi-menu</v-icon>
														</v-btn>
												</template>
												<v-list dense nav>
													<download-csv
														:data   = "dataTableFarmacias"
														:separator-excel="true"
														name="farmacias.csv"
														encoding="windows-1252"
														>
														<v-icon color="grey lighten-1"
														>mdi-microsoft-excel</v-icon>
														Exportar CSV
													</download-csv>
												</v-list>
												
										</v-menu>
								</v-col>
						</v-row>
						<v-data-table
								class="elevation-0 tabela"
								:headers="headersFarmacias"
								:items="dataTableFarmacias"
								:items-per-page="10"
								:header-props="{ sortIcon: 'mdi-chevron-down' }"
								multi-sort
						>
						</v-data-table>
						<v-overlay :absolute="true" :value="loadLista">
								<v-progress-circular indeterminate size="40" />
						</v-overlay>
				</v-card>
			</v-col>
			<Overlay :overlay="load" />
		</v-row>
	</v-container>
</template>

<script>
import { computed } from "@vue/composition-api";
import Overlay from "./_components/Overlay.vue";

export default {
  name: 'PlanilhaFarmacias',
	components: {
		Overlay,
	},
	data() {
		return {
      busca : '',
			dialogoImportar: false,
			itemUpdate: null,
			load: false,
			carregando: false,
			status: 'INICIADO',
			loadLista: true,
			data_aprovacao: null,
            data_invetario: null,
			// permiteEdicao: false,
			total: 0,
			pagination: null,
			dataTableFarmacias: [],
			headersFarmacias: [
				{ text:"Bandeira", value: "BANDEIRA", align: "center" },
				{ text:"Id Java", value: "JAVA", align: "center" },
				{ text:"Cód. Int.", value: "CÓD. INT.", align: "center" },
				{ text:"Cód. Hist", value: "CÓD. HIST.", align: "center" },
				{ text:"Nome", value: "NOME", align: "center" },
				{ text:"Região", value: "REGIÃO 2023", align: "center" },
				{ text:"Diretor", value: "DIRETOR 2023", align: "center" },
				{ text:"Email Regional", value: "E-MAIL REGIONAL", align: "center" },
				{ text:"RG", value: "RG", align: "center" },
				{ text:"Endereços", value: "ENDEREÇOS", align: "center" },
				{ text:"CEP", value: "CEP", align: "center" },
				{ text:"Bairro", value: "BAIRRO", align: "center" },
				{ text:"Cidade", value: "CIDADE", align: "center" },
				{ text:"Estado", value: "ESTADO", align: "center" },
				{ text:"Email", value: "E-MAIL", align: "center" },
				{ text:"CNPJ", value: "CNPJ", align: "center" },
				{ text:"Inaguração", value: "INAUGURAÇÃO", align: "center" },
				{ text:"Perfil", value: "PERFIL", align: "center" },
				{ text:"DDD", value: "DDD", align: "center" },
				{ text:"Telefone", value: "TELEFONE", align: "center" },
				{ text:"Projeto Serviços", value: "PROJETO SERVIÇOS", align: "center" },
				{ text:"Projetos Vacinas", value: "PROJETO VACINAS", align: "center" },
				{ text:"Aplicação", value: "APLICAÇÃO", align: "center" },
				{ text:"Covid", value: "COVID", align: "center" },
				{ text:"Serviços Venda", value: "SERVIÇOS_VENDA", align: "center" },
				{ text:"Vacinas Venda", value: "VACINAS_VENDA", align: "center" },
				{ text:"TLR", value: "TLR", align: "center" },
			],
			dataTableVitrine: [],
			regra: {
				xlsx: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(!!v && [".xlsx", ".XLSX"].includes(v.name.substr(-5))) ||
						"O arquivo deve ser do tipo XLSX.",
				],
				
			},
			isXlsx: false,
      		arquivoXLSX: '',
		};
	},
	methods: {
		abrirImportar() {

            this.arquivoXLSX = null;
            this.erro = false;


            this.dialogoImportar = true;
		},
		async importar() {
            this.carregando = true;

            var data = { guiadebolso_id: localStorage.guiadebolso_id };

            var formData = new FormData();

            formData.append("files", this.arquivoXLSX);
            formData.append("data", JSON.stringify(data));

            this.arquivoCSV = null;

            try {
                this.$refs.form.resetValidation();
            } catch (error) {
                //console.log(error)
            }

            var response = await this.$http().post(
                "/importacaoinventario/importarfarmacias/",
                formData
            );

            this.carregando = false;

            if (response.data.sucesso) {
                this.abrirFarmacias();
                this.dialogoImportar = false;
            } else {
                this.abrirFarmacias();
                this.erros = response.data.erros;
                this.pagination.page = 1;
                this.erro = true;
            }
		},
        async abrirFarmacias () {
            this.loadLista = true;
            var response = await this.$http().get("/importacaoinventario/dadosfarmacias/" + localStorage.guiadebolso_id);

            if ((response || { status: 500 }).status != 200) {
                this.$dialog.notify.error(
                    "Falha buscar os dados, por favor, tente novamente.",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                this.loadLista = false;
                return null;
            }
						console.log(response)
						this.permiteEdicao = (response.data.data_importacao.length == 0)
            this.dataTableFarmacias = response.data.farmacias
            this.data_aprovacao = response.data.data_aprovacao
            this.data_invetario = response.data.data_invetario
			this.status = response.data.status
            this.loadLista = false;
        }
	},
    async mounted() {
        this.abrirFarmacias()        
    }
};
</script>

<style></style>
