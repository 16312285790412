<template>
	<v-container fluid class=" py-0">
		<v-card class="pa-5 filtro-borda" elevation="0">
				<v-row>
					<v-col cols="3" md="3" lg="3" xl="3" class="px-3">
						<v-btn block class="white--text" color="primary" @click="abrirImportar" :disabled="['APROVADO','PROCESSADO'].includes(status)">
							<v-icon left>mdi-upload</v-icon> IMPORTAR
						</v-btn>
					</v-col>
					<v-col cols="3" md="3" lg="3" xl="3" class="px-4">
						<v-btn block class="white--text" color="primary" @click="gerarInventario" :disabled="!['APROVADO','IMPORTADO','PROCESSADO'].includes(status)">
							<v-icon left>mdi-download</v-icon> BAIXAR INVENTÁRIO
						</v-btn>
					</v-col>
					<v-col cols="3" md="3" lg="3" xl="3" class="px-4">
						<!-- <v-btn block class="white--text" color="primary" @click="abrirImportarCheckout"
						:disabled="!['APROVADO'].includes(status)">
							<v-icon left>mdi-run</v-icon> EXECUTAR PROCESSO FILIAIS
						</v-btn> -->
					</v-col>
					<v-col cols="3" md="3" lg="3" xl="3" class="px-4 d-flex justify-end" v-if="['APROVADO'].includes(status)">
						<v-btn block class="white--text" color="yellow" @click="desaprovar"
						:disabled="status != 'APROVADO'">
							<v-icon left>mdi-run</v-icon> REPROVAR
						</v-btn>
					</v-col>
					<v-col cols="3" md="3" lg="3" xl="3" class="px-4 d-flex justify-end" v-else>
						<v-btn block class="white--text" color="primary" @click="aprovar"
						:disabled="['EXECUTADO','INICIADO'].includes(status)">
							<v-icon left>mdi-run</v-icon> APROVAR
						</v-btn>
					</v-col>

				</v-row>
		</v-card>
		<v-dialog v-model="dialogoImportarCheckout" width="60%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Importar Check-Out para
					{{ $moment(mes).format("MMMM [de] YYYY") }}
				</v-card-title>
				<v-card-text>
					<v-alert class="mt-4" text type="info" border="left" style="font-size: 12px;">
						<ul>
							<li>
								A base estará disponivel enquanto não for importado o inventário.
							</li>
						</ul>
					</v-alert>
					<v-form v-model="validaForm">
						<v-row no-gutters class="mt-4">
							<v-col cols="12" sm="12" md="12" lg="12" xl="12" class="px-2">
								<v-text-field outlined dense disabled label="Arquivo criado em:"
									v-model="baseCheckout.data_criacao"></v-text-field>
							</v-col>


						</v-row>
					</v-form>
                    <v-row no-gutters class="mb-4" v-show="progresso">
                        <v-col cols="12" class="px-2">
                            <v-progress-linear v-model="index" height="25">
                                <span class="font-weight-bold white--text"
                                    >{{ Math.ceil(index) }}%</span
                                >
                            </v-progress-linear>
                        </v-col>
                    </v-row>
					<v-expand-transition>
						<div v-show="erro">
							<v-row no-gutters class="mb-0">
								<v-col cols="6">
									<download-csv :data="erros" :labels="errosLabels" name="erros.csv" delimiter=";">
										<v-btn color="primary">
											<v-icon left>mdi-microsoft-excel</v-icon>
											Exportar Erros
										</v-btn>
									</download-csv>
								</v-col>
							</v-row>
							<br />
							<v-data-table :headers="headerErros" :items="erros" :items-per-page="5" :options.sync="paginationErros"
								class="elevation-0 data-table-2"></v-data-table>
						</div>
					</v-expand-transition>
					<v-overlay :absolute="true" :value="loadListaCheckout">
						<v-progress-circular indeterminate size="40" />
					</v-overlay>
                    
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="primary" class="pr-4" :disabled="importado" @click="processoFiliais">
						<v-icon left>mdi-upload</v-icon> Importar
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" class="pr-4" @click="dialogoImportarCheckout = false">
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogoImportar" width="90%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Importar planilha para guia de bolso

				</v-card-title>
				<v-card-text>
					<v-alert class="mt-4" text type="info" border="left" style="font-size: 12px;">
						<ul>
							<li>
								Confirme o envio da planilha guia de bolso corretamente
							</li>

						</ul>
					</v-alert>
					<v-row no-gutters class="mt-4">


						<v-col cols="6" class="px-2">
							<v-form ref="form" v-model="isXlsx">
								<v-file-input label="Arquivo XLSX" outlined dense v-model="arquivoXLSX" :rules="regra.xlsx"
									accept=".xlsx"></v-file-input>
							</v-form>
						</v-col>
						<v-col cols="3" class="px-2">
							<v-btn color="primary" :disabled="!isXlsx" @click="importar()">
								<v-icon left>mdi-upload</v-icon>
								Importar Arquivo
							</v-btn>
						</v-col>
					</v-row>
					<v-overlay :value="carregando">
						<v-progress-circular indeterminate size="64"></v-progress-circular>
					</v-overlay> 
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" class="pr-4" @click="dialogoImportar = false">
						<v-icon left>mdi-close</v-icon> Fechar
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>


		<v-row>
			<v-col cols="12">
				<v-card class="px-5 py-3 br-15 w-100 my-6" elevation="0">
					<v-row class="mb-2">

						<v-col cols="10" class="pt-2">
							<h2 class="text-center" style="font-weight: normal">
								Guia Refinado
							</h2>
						</v-col>
						<v-col cols="2" class="d-flex justify-end pt-2">
							<v-menu offset-y min-width="180">
								<template v-slot:activator="{ on, attrs }">
									<v-btn dark color="#f2f2f2" v-bind="attrs" v-on="on" small class="px-1" style="min-width: unset">
										<v-icon color="#CCC">mdi-menu</v-icon>
									</v-btn>
								</template>
								<v-list dense nav>
									<download-csv :data="dataTableGuia" :separator-excel="true" name="guia_refinado.csv"
										encoding="windows-1252">
										<v-icon color="grey lighten-1">mdi-microsoft-excel</v-icon>
										Exportar CSV
									</download-csv>
								</v-list>

							</v-menu>
						</v-col>
					</v-row>
					<v-data-table class="elevation-0 tabela" :headers="headersGuia" :items="dataTableGuia" :items-per-page="5"
						:header-props="{ sortIcon: 'mdi-chevron-down' }" multi-sort>
					</v-data-table>
					<v-overlay :absolute="true" :value="loadLista">
						<v-progress-circular indeterminate size="40" />
					</v-overlay>
				</v-card>
			</v-col>
			<v-col cols="12">
				<v-card class="px-5 py-3 br-15 w-100 my-6" elevation="0">
					<v-row class="mb-2">

						<v-col cols="10" class="pt-2">
							<h2 class="text-center" style="font-weight: normal">
								Base Limpa
							</h2>
						</v-col>
						<v-col cols="2" class="d-flex justify-end pt-2">
							<v-menu offset-y min-width="180">
								<template v-slot:activator="{ on, attrs }">
									<v-btn dark color="#f2f2f2" v-bind="attrs" v-on="on" small class="px-1" style="min-width: unset">
										<v-icon color="#CCC">mdi-menu</v-icon>
									</v-btn>
								</template>
								<v-list dense nav>
									<download-csv :data="dataTableBaseLimpa" :separator-excel="true" name="base_limpa.csv"
										encoding="windows-1252">
										<v-icon color="grey lighten-1">mdi-microsoft-excel</v-icon>
										Exportar CSV
									</download-csv>
								</v-list>

							</v-menu>
						</v-col>
					</v-row>
					<v-data-table class="elevation-0 tabela" :headers="headersBaseLimpa" :items="dataTableBaseLimpa"
						:items-per-page="5" :header-props="{ sortIcon: 'mdi-chevron-down' }" multi-sort>
					</v-data-table>
					<v-overlay :absolute="true" :value="loadLista">
						<v-progress-circular indeterminate size="40" />
					</v-overlay>
				</v-card>
			</v-col>
			<v-col cols="12">
				<v-card class="px-5 py-3 br-15 w-100 my-6" elevation="0">
					<v-row class="mb-2">

						<v-col cols="10" class="pt-2">
							<h2 class="text-center" style="font-weight: normal">
								Base Modelo Checkout
							</h2>
						</v-col>
						<v-col cols="2" class="d-flex justify-end pt-2">
							<v-menu offset-y min-width="180">
								<template v-slot:activator="{ on, attrs }">
									<v-btn dark color="#f2f2f2" v-bind="attrs" v-on="on" small class="px-1" style="min-width: unset">
										<v-icon color="#CCC">mdi-menu</v-icon>
									</v-btn>
								</template>
								<v-list dense nav>
									<download-csv :data="dataTableBaseModeloCheckout" :separator-excel="true"
										name="base_modelo_checkout.csv" encoding="windows-1252">
										<v-icon color="grey lighten-1">mdi-microsoft-excel</v-icon>
										Exportar CSV
									</download-csv>
								</v-list>

							</v-menu>
						</v-col>
					</v-row>
					<v-data-table class="elevation-0 tabela" :headers="headersBaseModeloCheckout"
						:items="dataTableBaseModeloCheckout" :items-per-page="5" :header-props="{ sortIcon: 'mdi-chevron-down' }"
						multi-sort>
					</v-data-table>
					<v-overlay :absolute="true" :value="loadLista">
						<v-progress-circular indeterminate size="40" />
					</v-overlay>
				</v-card>
			</v-col>
			<v-overlay :value="carregando">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>            
			<Overlay :overlay="load" />
		</v-row>
        
	</v-container>
</template>

<script>
// import Modal from "./../../components/Modal.vue";
// import HeaderFerramentas from "./_components/HeaderFerramentas.vue";
// import InformacoesGeraisAdd from "./_components/InformacoesGeraisAdd.vue";
// import ImportacaoFerramentas from "./_components/ImportacaoFerramentas.vue";
import moment from 'moment'
import Overlay from "./_components/Overlay.vue";

export default {
	name: 'PlanilhaFarmacias',
	components: {
		// HeaderFerramentas,
		// Modal,
		// InformacoesGeraisAdd,
		// ImportacaoFerramentas,
		Overlay,
	},
	data() {
		return {
			carregando: false,
			status: 'INICIADO',
            index: 0,
            progresso: false,
			busca: "",
			loadListaCheckout: false,
			baseCheckout: {},
			importado: false,
			erros: [],
			validaForm: false,
			errosLabels: { msg: "Descrição do Erro" },
			headerErros: [
				{ text: "Descrição do Erro", value: "msg", align: "left" },
			],
			paginationErros: {},
			erro: false,
			permiteEdicao: false,
			load: false,
			mes: moment().toDate(),
			loadLista: true,
			dialogoImportarCheckout: false,
			dialogoImportar: false,
			permitirProcessoFiliais: false,
			total: 0,
			permitirGerar: false,
			pagination: null,
			dataTableGuia: [],
			headersGuia: [
				{ text: "ID JAVA", value: "ID JAVA", align: "center" },
				{ text: "BANDEIRA", value: "BANDEIRA", align: "center" },
				{ text: "ESTADO", value: "ESTADO", align: "center" },
				{ text: "CLUSTER", value: "CLUSTER", align: "center" },
				{ text: "CLUSTER TABELA", value: "CLUSTER TABELA", align: "center" },
				{ text: "TAMANHO", value: "TAMANHO", align: "center" },
				{ text: "MODELO CKO", value: "MODELO CKO", align: "center" },
				{ text: "DE ENTRADA", value: "DE ENTRADA", align: "center" },
				{ text: "DE CORREDOR", value: "DE CORREDOR", align: "center" },
				{ text: "DE BALCÃO", value: "DE BALCÃO", align: "center" },
				{ text: "TIPO DE PONTA", value: "TIPO DE PONTA", align: "center" },
				{ text: "FARMACINHA", value: "FARMACINHA", align: "center" },
				{ text: "Qtd MS", value: "Qtd MS", align: "center" },
				{ text: "Qtd FU", value: "Qtd FU", align: "center" },
				{ text: "Qtd MOVEL PONTA", value: "Qtd MOVEL PONTA", align: "center" },
				{ text: "MOVEL ILHA", value: "MOVEL ILHA", align: "center" },
				{ text: "CAPA DE ANTENA", value: "CAPA DE ANTENA", align: "center" },
				{ text: "CUBO", value: "CUBO", align: "center" },
				{ text: "CESTÃO", value: "CESTÃO", align: "center" },
				{ text: "DISPLAY BALCÃO RD", value: "DISPLAY BALCÃO RD", align: "center" },
				{ text: "PALLET DE LEITES", value: "PALLET DE LEITES", align: "center" },
				{ text: "PALLET FRALDAS", value: "PALLET FRALDAS", align: "center" },
				{ text: "HOT NOW", value: "HOT NOW", align: "center" },
				{ text: "Capa de Balança", value: "Capa de Balança", align: "center" },
				{ text: "VITRINES DIGITAIS", value: "VITRINES DIGITAIS", align: "center" },
				{ text: "DISPLAY DE ACRÍLICO RX", value: "DISPLAY DE ACRÍLICO RX", align: "center" },
				{ text: "TAPETINHO", value: "TAPETINHO", align: "center" },
				{ text: "TOPO DE CUBO", value: "TOPO DE CUBO", align: "center" },
				{ text: "PONTA DE GÔNDOLA VIDA SAUDÁVEL", value: "PONTA DE GÔNDOLA VIDA SAUDÁVEL", align: "center" },
				{ text: "PALLET DE FRALDAS PERSONALIZADO", value: "PALLET DE FRALDAS PERSONALIZADO", align: "center" },
			],
			dataTableBaseLimpa: [],
			headersBaseLimpa: [
				{ text: "ID JAVA", value: "ID JAVA", align: "center" },
				{ text: "BANDEIRA", value: "BANDEIRA", align: "center" },
				{ text: "MODELO CKO", value: "MODELO CKO", align: "center" },
				{ text: "TIPO DE PONTA", value: "TIPO DE PONTA", align: "center" },
				{ text: "GANCHEIRAS CKO", value: "GANCHEIRAS CKO", align: "center" },
				{ text: "PRATELEIRAS CKO", value: "PRATELEIRAS CKO", align: "center" },
				{ text: "PRATELEIRAS CKO DROGASIL ANTIGA", value: "PRATELEIRAS CKO DROGASIL ANTIGA", align: "center" },
				{ text: "GANCHEIRA CKO DROGASIL ANT A", value: "GANCHEIRA CKO DROGASIL ANT A", align: "center" },
				{ text: "CKO DISPLAY GANCHEIRA G3", value: "CKO DISPLAY GANCHEIRA G3", align: "center" },
				{ text: "DISPLAY CKO", value: "DISPLAY CKO", align: "center" },
				{ text: "TELHADINHO CKO", value: "TELHADINHO CKO", align: "center" },
				{ text: "PRAT. ESPECIAIS CKO", value: "PRAT. ESPECIAIS CKO", align: "center" },
				{ text: "PTA. ENTRADA (DUPLAS)", value: "PTA. ENTRADA (DUPLAS)", align: "center" },
				{ text: "PTA. CORREDOR (DUPLAS)", value: "PTA. CORREDOR (DUPLAS)", align: "center" },
				{ text: "PTA. BALCÃO (DUPLAS)", value: "PTA. BALCÃO (DUPLAS)", align: "center" },
				{ text: "PTA. ENTRADA (INTEIRAS)", value: "PTA. ENTRADA (INTEIRAS)", align: "center" },
				{ text: "PTA. CORREDOR (INTEIRAS)", value: "PTA. CORREDOR (INTEIRAS)", align: "center" },
				{ text: "PTA. BALCÃO (INTEIRAS)", value: "PTA. BALCÃO (INTEIRAS)", align: "center" },
				{ text: "FARMACINHA", value: "FARMACINHA", align: "center" },
				{ text: "Qtd MS", value: "Qtd MS", align: "center" },
				{ text: "Qtd FU", value: "Qtd FU", align: "center" },
				{ text: "Qtd DISPLAY BALCÃO DE MADEIRA PEQUENO", value: "Qtd DISPLAY BALCÃO DE MADEIRA PEQUENO", align: "center" },
				{ text: "TAPETINHO", value: "TAPETINHO", align: "center" },
				{ text: "Qtd MOVEL PONTA", value: "Qtd MOVEL PONTA", align: "center" },
				{ text: "MOVEL ILHA", value: "MOVEL ILHA", align: "center" },
				{ text: "CAPA DE ANTENA", value: "CAPA DE ANTENA", align: "center" },
				{ text: "PALLET DE LEITES PEQUENOS", value: "PALLET DE LEITES PEQUENOS", align: "center" },
				{ text: "PALLET FRALDAS GRANDES", value: "PALLET FRALDAS GRANDES", align: "center" },
				{ text: "CUBO - 1/4", value: "CUBO - 1/4", align: "center" },
				{ text: "CUBO - METADE", value: "CUBO - METADE", align: "center" },
				{ text: "CUBO - INTEIRO", value: "CUBO - INTEIRO", align: "center" },
				{ text: "TOPO DE CUBO", value: "TOPO DE CUBO", align: "center" },
				{ text: "ADESIVO VITRINE", value: "ADESIVO VITRINE", align: "center" },
				{ text: "CANAL VITRINE", value: "CANAL VITRINE", align: "center" },
				{ text: "VITRINE", value: "VITRINE", align: "center" },
				{ text: "Campanha Exclusiva", value: "Campanha Exclusiva", align: "center" },
				{ text: "Clip Strip Personalizado", value: "Clip Strip Personalizado", align: "center" },
				{ text: "Display de Balcão (P)", value: "Display de Balcão (P)", align: "center" },
				{ text: "Display de Balcão (G)", value: "Display de Balcão (G)", align: "center" },
				{ text: "DISPLAY DE ACRÍLICO RX", value: "DISPLAY DE ACRÍLICO RX", align: "center" },
				{ text: "Display de Chão", value: "Display de Chão", align: "center" },
				{ text: "Display de Geladeira", value: "Display de Geladeira", align: "center" },
				{ text: "Display de Seção (acima de 30cm)", value: "Display de Seção (acima de 30cm)", align: "center" },
				{ text: "Display de Seção 30cm", value: "Display de Seção 30cm", align: "center" },
				{ text: "e-Learning", value: "e-Learning", align: "center" },
				{ text: "Faixa de Gondola", value: "Faixa de Gondola", align: "center" },
				{ text: "Faixa de Gondola (acima de 30)", value: "Faixa de Gondola (acima de 30)", align: "center" },
				{ text: "Faixa de Gondola (até 30)", value: "Faixa de Gondola (até 30)", align: "center" },
				{ text: "Móvel Beleza 120cm", value: "Móvel Beleza 120cm", align: "center" },
				{ text: "Móvel Beleza 30 cm - NUTRICOSMÉTICOS", value: "Móvel Beleza 30 cm - NUTRICOSMÉTICOS", align: "center" },
				{ text: "Móvel Beleza 30cm", value: "Móvel Beleza 30cm", align: "center" },
				{ text: "Móvel Beleza 60 cm - NUTRICOSMÉTICOS", value: "Móvel Beleza 60 cm - NUTRICOSMÉTICOS", align: "center" },
				{ text: "Móvel Beleza 60cm", value: "Móvel Beleza 60cm", align: "center" },
				{ text: "Móvel Beleza 90cm", value: "Móvel Beleza 90cm", align: "center" },
				{ text: "Orelha de Monitor", value: "Orelha de Monitor", align: "center" },
				{ text: "PG Temática - PRAT-1A", value: "PG Temática - PRAT-1A", align: "center" },
				{ text: "PG Temática - PRAT-1B", value: "PG Temática - PRAT-1B", align: "center" },
				{ text: "PG Temática - PRAT-1C", value: "PG Temática - PRAT-1C", align: "center" },
				{ text: "PG Temática - PRAT-2A", value: "PG Temática - PRAT-2A", align: "center" },
				{ text: "PG Temática - PRAT-2B", value: "PG Temática - PRAT-2B", align: "center" },
				{ text: "PG Temática - PRAT-2C", value: "PG Temática - PRAT-2C", align: "center" },
				{ text: "PG Temática - PRAT-3A", value: "PG Temática - PRAT-3A", align: "center" },
				{ text: "PG Temática - PRAT-3B", value: "PG Temática - PRAT-3B", align: "center" },
				{ text: "PG Temática - PRAT-3C", value: "PG Temática - PRAT-3C", align: "center" },
				{ text: "PG Temática - PRAT-4A", value: "PG Temática - PRAT-4A", align: "center" },
				{ text: "PG Temática - PRAT-4B", value: "PG Temática - PRAT-4B", align: "center" },
				{ text: "PG Temática - PRAT-4C", value: "PG Temática - PRAT-4C", align: "center" },
				{ text: "Stopper", value: "Stopper", align: "center" },
				{ text: "Testeiras Genericos", value: "Testeiras Genericos", align: "center" },
				{ text: "Totem", value: "Totem", align: "center" },
				{ text: "Wobbler", value: "Wobbler", align: "center" },
				{ text: "Hot Now", value: "Hot Now", align: "center" },
				{ text: "Capa de Balança", value: "Capa de Balança", align: "center" },
				{ text: "PALLET DE FRALDAS PERSONALIZADO", value: "PALLET DE FRALDAS PERSONALIZADO", align: "center" },
				{ text: "PONTA DE GÔNDOLA VIDA SAUDÁVEL", value: "PONTA DE GÔNDOLA VIDA SAUDÁVEL", align: "center" },
			],
			headersBaseModeloCheckout: [
				{ text: "ID JAVA", value: "ID JAVA", align: "center" },
				{ text: "CLUSTER", value: "CLUSTER", align: "center" },
				{ text: "CLUSTER TABELA", value: "CLUSTER TABELA", align: "center" },
				{ text: "MODELO CKO", value: "MODELO CKO", align: "center" },
				{ text: "TAMANHO", value: "TAMANHO", align: "center" },
				{ text: "EMAIL", value: "EMAIL", align: "center" },
			],
			dataTableBaseModeloCheckout: [],
			regra: {
				xlsb: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(!!v && [".xlsx", ".XLSX"].includes(v.name.substr(-5))) ||
						"O arquivo deve ser do tipo XLSX.",
				],

			},
			isXlsx: false,
			arquivoXLSX: '',
		};
	},
	methods: {
		async desaprovar () {
			this.carregando = true;
			this.loadLista = true;
			var response = await this.$http().get("/importacaoinventario/desaprovar/" + localStorage.guiadebolso_id);
			this.abrirGuias()
		},
		async aprovar () {
			console.log('aqui')
			this.carregando = true;
			this.loadLista = true;
			var data = {
				mes: moment().format('YYYY-MM') + "-01",
				guiadebolso_id: localStorage.guiadebolso_id,
			};			
			var response = await this.$http().get("/importacaoinventario/aprovar/" + localStorage.guiadebolso_id);
			var response2 = await this.$http().get(
				"/filial/importarcheckout",{
                    params: data,			
				})	
			this.abrirGuias()
		},
		async gerarInventario() {
			// console.log('aqiuoi')
			this.carregando = true;
			this.loadLista = true;
			window.location = this.$http("baseURL") + "/importacaoinventario/inventarioprocessado/" + localStorage.guiadebolso_id;
			this.loadLista = false;
			this.carregando = false;
		},
		abrirImportar() {

			this.arquivoXLSX = null;
			this.erro = false;


			this.dialogoImportar = true;
		},
		async importar() {
			this.carregando = true;
            this.index = 0;
            this.progresso = true;
			var data = { guiadebolso_id: localStorage.guiadebolso_id };

			var formData = new FormData();

			formData.append("files", this.arquivoXLSX);
			formData.append("data", JSON.stringify(data));

			this.arquivoCSV = null;

			// try {
			// 		this.$refs.form.resetValidation();
			// } catch (error) {
			// 		//console.log(error)
			// }
			console.log('antes 15')
			// await setTimeout(async function() {
  				// código a ser executado após 5 segundos
				  console.log('pos 15')
			
			var response = await this.$http().post(
				"/importacaoinventario/importarguia",
				formData,
                
			);

			this.carregando = false;

			if (response.data.sucesso) {
				this.abrirGuias();
				this.dialogoImportar = false;
			} else {
				this.abrirGuias();
				this.erros = response.data.erros;
				this.pagination.page = 1;
				this.erro = true;
			}
			// }, 15000);
		},
		async abrirGuias() {
			this.carregando = true;
			this.loadLista = true;

			var response = await this.$http().get("/importacaoinventario/dadosguia/" + localStorage.guiadebolso_id);

			if ((response || { status: 500 }).status != 200) {
				this.$dialog.notify.error(
					"Falha buscar os dados, por favor, tente novamente.",
					{
						position: "top-right",
						timeout: 5000,
					}
				);
				this.loadLista = false;
				return null;
			}
			if (response.data.sucesso == 0) {
				this.$dialog.notify.error(
					response.data.erros[0].msg,
					{
						position: "top-right",
						timeout: 5000,
					}
				);
				this.loadLista = false;
				return null;
			}
			// console.log(response)
			this.dataTableGuia = response.data.guia
			this.dataTableBaseLimpa = response.data.base_limpa
			this.dataTableBaseModeloCheckout = response.data.base_modelo_checkout
            this.data_aprovacao = response.data.data_aprovacao
            this.data_invetario = response.data.data_invetario
			this.permiteEdicao = (response.data.data_importacao.length == 0)
			this.permitirGerar = response.data.base_limpa ? response.data.base_limpa.length > 0 : false
			this.permitirProcessoFiliais = this.permitirGerar ? (response.data.data_importacao_checkout.length == 0) : false
			this.status = response.data.status
			console.log(this.status)
			this.loadLista = false;
			this.carregando = false;
		},
		async abrirImportarCheckout() {
			// if (!this.$refs.formFiltro.validate()) return;
			var response = await this.$http().get("/importacaoinventario/habasecheckoutparaimportar");
			if ((response || { status: 500 }).status != 200) {
				this.$dialog.notify.error(
					"Falha buscar os dados, por favor, tente novamente.",
					{
						position: "top-right",
						timeout: 5000,
					}
				);
				this.loadLista = false;
				return null;
			}
			if (!response.data.haBaseCheckout) {
				this.$dialog.notify.error(
					"Não há uma base esperando pra ser importada.",
					{
						position: "top-right",
						timeout: 5000,
					}
				);
				this.loadLista = false;
				return null;

			}
			this.baseCheckout = response.data
			this.dialogoImportarCheckout = true;
		},
		async processoFiliais() {
			this.loadListaCheckout = true;

            this.index = 0;
            this.progresso = true;

			var data = {
				mes: moment().format('YYYY-MM') + "-01",
				guiadebolso_id: localStorage.guiadebolso_id,
			};

			//var formData = new FormData();

			//formData.append("data", JSON.stringify(data));

			var response = await this.$http().get(
				"/filial/importarcheckout",{
                    params: data,
                    onDownloadProgress: (progressEvent) => {
                        let retorno =
                            progressEvent.currentTarget.response.split(";");

                        let _i =
                            retorno.length > 1 ? retorno.length - 2 : 0;
                        let obj = JSON.parse(retorno[_i]);

                        console.log(obj)

                        this.index = obj.index || 0;

                        if (obj.url) {
                            url = obj.url;
                        }

                        if (obj.msg) msgError = obj.msg;
                    },
                }
			);

            this.progresso = false;

			// this.loadLista = false;
            console.log(response)

			if (this.index == 100) {
				// this.filtrar();
				this.dialogoImportarCheckout = false;
				this.importado = true
				this.permitirProcessoFiliais = false
			} else {
				// this.filtrar();
				this.erros = response.data.erros;
				// this.pagination.page = 1;
				this.permitirProcessoFiliais = false
				this.erro = true;
			}
			this.loadListaCheckout = false;
		}
	},
	async mounted() {
		this.abrirGuias()
	}
};
</script>

<style></style>
